import React from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import ToggleContent from "./toggleContent"

function ContactDrawer({...props}) {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();
    
    return (
      <Box display={["block", "block", "none"]} {...props}>
          <Button
          bg="white"
          variant="outline"
          shadow="sm"
          fontWeight="medium"
          fontSize="md"
          ref={btnRef}
          _hover={{ bg: "gray.200" }}
          _active={{ bg: "gray.300" }}
          onClick={onOpen}
        >
          Elérhetőségek
        </Button>
          <Drawer
            isOpen={isOpen}
            placement="bottom"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay>
              <DrawerContent dipslay="flex">
                <DrawerCloseButton size="lg" width={12} height={12} top="18px" right="10px" />
                <DrawerHeader fontSize="2xl" py="6">Elérhetőségek</DrawerHeader>
                <DrawerBody py={0} px="6">
                    {isOpen ? (<ToggleContent/>) : null}
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
      </Box>
    );
  }

  export default ContactDrawer;