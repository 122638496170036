import React, { useState, useCallback, useRef } from "react";
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby";
import SEO from "../components/seo";
import {
  Flex,
  Box,
  Heading,
  Text,
  LinkBox,
  LinkOverlay,
  Avatar,
  HStack,
  Image,
} from "@chakra-ui/react";
import Layout from "../components/layout-2";
import ContactDrawer from "../components/atoms/contactDrawer";
import MenuDrawer from "../components/atoms/menuDrawer";
import useHorizontal from "../hooks/@oberon-amsterdam/horizontal/hook";
import useWindowSize from "../hooks/useWindowSize";
import img from "../images/gabor_tamas_portrait_mobile.jpg"
import PropTypes from "prop-types";

// useRef in useEffect issue solution --> https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
function useHookWithRefCallback() {
  const ref = useRef(null)
  const setRef = useCallback(node => {
    if (ref.current) {
      // Make sure to cleanup any events/references added to the last instance
    }
    
    if (node) {
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
    }
    
    // Save a reference to the.
    ref.current = node
  }, [])
  
  return [setRef]
}

function Index() {
  const data = useStaticQuery(graphql`
    query IndexImages2 {
      A: allCloudinaryMedia(filter: { tags: { in: "cover", eq: "ingatlan" } }) {
        edges {
          node {
            secure_url
            tags
          }
        }
      }
      B: allCloudinaryMedia(filter: { tags: { in: "cover", eq: "enterior" } }) {
        edges {
          node {
            secure_url
            tags
          }
        }
      }
      C: allCloudinaryMedia(filter: { tags: { in: "cover", eq: "epulet" } }) {
        edges {
          node {
            secure_url
            tags
          }
        }
      }
      D: allCloudinaryMedia(filter: { tags: { in: "cover", eq: "varoskep" } }) {
        edges {
          node {
            secure_url
            tags
          }
        }
      }
    }
  `);

  const [ref] = useHookWithRefCallback()

  if (typeof window != "undefined") {
    if (window > breakpoint) {
      document.documentElement.style.overscrollBehavior = "none";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overscrollBehavior = "auto";
      document.documentElement.style.overflow = "auto";
    }
  }

  // Store which element the useHorizontal hook will be reffered to.
  const [scrollContainer, setContainer] = useState();

  // Capture and store screen size
  const { width } = useWindowSize();
  const breakpoint = 1024;

  // console.log(width);

  useHorizontal({ container: scrollContainer });

  return (
    <Layout>
      <SEO
        keywords={[
          `épület`,
          `enteriőr`,
          `ingatlan`,
          `iroda`,
          `lakás`,
          `építészeti`,
          `fotó`,
          `fotós`,
          `fotográfus`,
        ]}
        title="GÁBOR TAMÁS"
      />
      <Flex
        ref={() => {
          width > breakpoint ? setContainer(ref) : null;
        }}
        flexDirection={["column", "column", "row"]}
        p={0}
        m={0}
        mt={[6, 6, 0]}
        sx={{ height: ["100%", "100%", "calc(100vh - 192px)"] }}
      >
        <Flex
          alignItems="center"
          pl={[4, 4, 32]}
          pr={[4, 4, 24]}
          pb={[16, 16, 0]}
          minWidth={["60%", "60%", "50%"]}
        >
          <Box maxWidth={["md", "md", "2xl"]}>
            <Heading
              as="h1"
              fontSize={["4xl", "4xl", "5xl"]}
              fontWeight="black"
              lineHeight="short"
              color="black"
              mb={[6, 6, 10]}
            >
              Épület, enteriőr, ingatlan, városkép fotók készítése.
            </Heading>
              <HStack display={["flex", "flex", "none"]} spacing={4} mb={6}>
                <Avatar
                  size="xl"
                  color="white"
                  bg="brand.indigo.500"
                  src={img}
                  name="Gábor Tamás"
                  showBorder="true"
                  borderWidth="4px"
                  boxShadow="lg"
                />
                <ContactDrawer/>
              </HStack>
            <Text
              as="p"
              fontSize={["lg", "xl", "xl"]}
              fontWeight="normal"
              lineHeight="tall"
              color="black"
            >
              Tamás vagyok, Budapest székhelyű fotográfus. Tevékenyégi köröm az
              épületek, belső terek, ingatlanok és építési projektek igényhez
              igazított fotózása, portfólió, referencia, website tartalom, marketing,
              hirdetés és publikációs célra. Ha kitűnő képekre van szüksége keressen bizalommal.
            </Text>
          </Box>
        </Flex>

        <Card
          title="Ingatlanok"
          link="/ingatlanok"
          imageUrl={data.A.edges[0].node.secure_url}
          tags={data.A.edges[0].node.secure_url}
        />
        <Card
          title="Enteriőrök"
          link="/enteriorok"
          imageUrl={data.B.edges[0].node.secure_url}
          tags={data.B.edges[0].node.secure_url}
        />
        <Card
          title="Épületek"
          link="/epuletek"
          imageUrl={data.C.edges[0].node.secure_url}
          tags={data.C.edges[0].node.secure_url}
        />
        <Card
          title="Városkép"
          link="/varoskep"
          imageUrl={data.D.edges[0].node.secure_url}
          tags={data.D.edges[0].node.secure_url}
        />
      </Flex>
      <MenuDrawer/>
    </Layout>
  );
}

const Card = (props) => {
  return (
    <Box
      mr={[0, 0, "5vh"]}
      _notLast={{mb: [6, 6, 0]}}
      minWidth="fit-content"
      _last={{ pr: [0, 0, 12] }}
      position="relative"
    >
      <LinkBox className="sx-group">
        <Flex
          style={{ willChange: `opacity` }}
          position="absolute"
          to={0}
          left={0}
          alignItems="center"
          width="full"
          height="full"
          verticalAlign="middle"
          bg={["#0000007d", "#0000007d", "transparent"]}
          _hover={{ bg: [null, null, "#0000007d"] }}
          sx={{
            transitionProperty: "background-color",
            transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
            transitionDuration: ".3s",
            transitionDelay: ".15s",
            willChange: "background",
            ".sx-group:hover &": {
              background: "#0000007d",
            },
          }}
        >
          <Heading
            mx="auto"
            fontSize="4xl"
            fontWeight="extrabold"
            letterSpacing="wide"
            textAlign="center"
            color="white"
            opacity={["1", "1", "0"]}
            sx={{
              flex: "0 1 auto",
              transitionProperty: "opacity",
              transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
              transitionDuration: ".3s",
              transitionDelay: ".15s",
              willChange: "opacity",
              ".sx-group:hover &": {
                opacity: [null, null, "1"],
              },
            }}
          >
            <LinkOverlay
              as={GatsbyLink}
              to={props.link}
              style={{ textDecoration: "none" }}
            >
              {props.title}
            </LinkOverlay>
          </Heading>
        </Flex>
        <Image
          loading="lazy"
          style={{
            height: `calc(100vh - 192px)`,
          }}
          objectFit="cover"
          src={props.imageUrl}
          alt={props.tags}
        />
      </LinkBox>
    </Box>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  imageUrl: PropTypes.string,
  tags: PropTypes.string,
};

Index.propTypes = {
  ref: PropTypes.any,
  url: PropTypes.string,
  tags: PropTypes.string,
};

export default Index;